import { Controller } from "@hotwired/stimulus"
import {enter, leave} from 'el-transition'; 

export default class extends Controller {
  static targets = ["button", "popup"]

  initialize() {
    this.popupTarget.classList.add('hidden')
  }

  // hide(event) {
  //   leave(this.popupTarget)
  // }

  hide(event) {
    leave(this.popupTarget)
  }

  show(event) {
    enter(this.popupTarget)
  }

  action(event) {
    event.preventDefault();
    if(this.popupTarget.classList.contains('hidden')) {
        enter(this.popupTarget)
    } else {
        leave(this.popupTarget)
    }
  }

}

